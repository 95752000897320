import React from 'react'
import { Link } from 'react-router-dom';
import frontend from "../assets/frontend.png";
import backend from "../assets/backend.png";
import fullstack from "../assets/fullstack.png"

function Webdevelopmentpage() {


    return (
        <div className='bg-white'>
            <div className='container'>
                {/* back to home selection */}
                <div className="h-25 w-50 pt-4 ">
                    <Link to="/" className='back-link '>
                        <span className='fs-4 p-2'>←</span>
                         Home
                    </Link>
                </div>
                {/* custom  selection */}


            </div>
            <div className="custom-text">
                <div className=" container ">
                    <h1 className='pt-2'>Custom Web App Development Services </h1>
                    <p className='pt-2'>Highly interactive experience for e-commerce and content publishing websites</p>
                    <button className='contact-btn pt-2'
                        onClick={() => window.open('https://wa.me/9360118099?text=Hello, I would like to know more about your services!', '_blank')}
                    >Contact Us</button>
                </div>
            </div>
            <div className="custom-project">
                <div className="container ">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 text-center">
                            <h2 className="fs-1 fw-bold p-2">36+</h2>
                            <p className='custom-project-text'>Projects Delivered</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 text-center">
                            <h2 className='fs-1 fw-bold p-2'>4+</h2>
                            <p className='custom-project-text'>Developers</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 text-center">
                            <h2 className='fs-1 fw-bold p-2'>10+</h2>
                            <p className='custom-project-text'>Technologies & Tools Used</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 text-center">
                            <h2 className='fs-1 fw-bold p-2'>100%</h2>
                            <p className='custom-project-text'>Client Satisfaction</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="web-tech-card">
                <div className="container">
                    <h2 style={{ fontFamily: "inter" }} class="text-center web-tech-span  ">Services <span className="web-tech-span">We Provide</span> in Web Application Development</h2>
                    <p className='text-center  text-secondary' style={{ fontFamily: "inter" }} >We engage in a variety of high-scope-oriented business ideas</p>

                    <div className="row mt-5 mb-5">
                        <div className="col-md-4 col-sm-6 col-xs-6 text-center">
                            <img src={frontend} alt="Frontend Development" width="60" height="60"  />
                            <h3 className="pt-3 text-secondary">Frontend Development</h3>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-6 text-center">
                            <img src={backend} alt="Backend Development" width="60" height="60" />
                            <h3 className="pt-3 text-secondary">Backend Development</h3>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-6 text-center">
                            <img src={fullstack} alt="Full Stack Development" width="60" height="60" />
                            <h3 className="pt-3 text-secondary">Full Stack Development</h3>
                        </div>
                    </div>


                </div>
            </div>

            <div className="web-process">
                <div className="container">
                    <h2 className="text-center mt-2" style={{ fontFamily: "inter", fontSize: "40px", paddingTop: "10px", fontWeight: "bold" }}>Check Out Our Work Process</h2>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center">
                            <div className="border rounded p-4 h-100 custom-hover-process">
                                <div className="d-flex justify-content-start" style={{ fontFamily: "inter" }}>
                                    <span className='fs-1 fw-bold ps-2'>1</span>
                                </div>
                                <h4 className='text-start'>Requirement Analysis</h4>
                                <p className='text-start ps-1'>
                                    Our requirement analysis begins with identifying and understanding the client's needs. We thoroughly gather and document all relevant information about the web app project. This stage involves defining the software's requirements to ensure everything is ready before development starts.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center">
                            <div className="border rounded p-4 h-100 custom-hover-process">
                                <div className="d-flex justify-content-start " style={{ fontFamily: "inter" }}>
                                    <span className='fs-1 fw-bold ps-2'>2</span>
                                </div>
                                <h4 className='text-start'>Mapping and Arranging</h4>
                                <p className='text-start ps-1'>
                                    The planning phase of web app development includes creating a sitemap and wireframes. Developers collect data and organize it into a structured format to illustrate the relationships between different pages, ensuring a clear understanding of the project's flow.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center ">
                            <div className="border rounded p-4 h-100 custom-hover-process">
                                <div className="d-flex justify-content-start" style={{ fontFamily: "inter" }}>
                                    <span className='fs-1 fw-bold ps-2'>3</span>
                                </div>
                                <h4 className='text-start'>User Interface & Experience</h4>
                                <p className='text-start ps-1'>
                                    During this phase, we design the visuals needed for the web app, including images, videos, and other media. We develop website layouts and present them for client review. Once the design for the user interface is finalized and approved, we proceed to the next stage.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5">
                            <div className="border rounded p-4 h-100 custom-hover-process"  >
                                <div className="d-flex justify-content-start" style={{ fontFamily: "inter" }}>
                                    <span className='fs-1 fw-bold ps-2'>4</span>
                                </div>
                                <h4 className='text-start'>Development and Integration</h4>
                                <p className='text-start ps-1'>
                                    In this stage, we develop the web app according to the approved design specifications. The technical team manages both frontend and backend development using a tech stack that includes HTML, CSS, and jQuery, ensuring seamless integration of all components.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5">
                            <div className="border rounded p-4 h-100 custom-hover-process">
                                <div className="d-flex justify-content-start" style={{ fontFamily: "inter" }}>
                                    <span className='fs-1 fw-bold ps-2'>5</span>
                                </div>
                                <h4 className='text-start'>Testing and Optimization</h4>
                                <p className='text-start ps-1'>
                                    Our dedicated testing team performs various levels of testing following the development phase to identify any errors or issues. This includes functionality and feature testing, as well as testing of web APIs, databases, regressions, and cross-compatibility across different browsers and operating systems.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center mt-5 ">
                            <div className="border rounded p-4 h-100 custom-hover-process">
                                <div className="d-flex justify-content-start" style={{ fontFamily: "inter" }}>
                                    <span className='fs-1 fw-bold ps-2'>6</span>
                                </div>
                                <h4 className='text-start'>Deployment and Launching</h4>
                                <p className='text-start ps-1'>
                                    Finally, we deploy the web app onto a suitable server. After thorough testing, the web app is launched with the latest tools and technologies, ensuring it is ready for the client and their end users.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>













    )
}

export default Webdevelopmentpage