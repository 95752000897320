import React from 'react'
import { Link } from 'react-router-dom';
import interview from "../assets/interview.jpg";
import hrservice from "../assets/hrservice.jpg";
import skill from "../assets/skill.jpg";
export default function Resourcepage() {
    return (
        <div>
            <div className='container'>
                <div className="h-25 w-50 pt-4 ">
                    <Link to="/" className='back-link '>
                        <span className='fs-4 p-2'>←</span>
                         Home
                    </Link>
                </div>
            </div>

            <div className="custom-text">
                <div className=" container ">
                    <h1 className='pt-2'>Resource Management Services </h1>
                    <p className='pt-2'>Empowering Manufacturing Through IT Solutions</p>
                    <button className='contact-btn pt-2'
                        onClick={() => window.open('https://wa.me/9360118099?text=Hello, I would like to know more about your services!', '_blank')}
                    >Contact Us</button>
                </div>
            </div>
            {/* interview selection */}
            <div className='interview-section'>
                <div class="container ">
                    <h1 style={{ fontFamily: "inter", fontWeight: "bold" }} class="text-center">Our  Services</h1>
                    <div class="row mt-5">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <h2 className='p-2'>1.Interview Services</h2>
                            <ul className='mt-2'>
                                <li className='p-1'><strong>Customized Interview Framework:</strong> We collaborate with you to create a tailored interview process that aligns with your organizational culture and technical requirements.</li>
                                <li className='p-1'><strong>Expert Assessments:</strong> Our skilled interviewers, with industry-specific knowledge, evaluate candidates not only for technical skills but also for cultural fit.</li>
                                <li className='p-1'><strong>Candidate Experience:</strong> We prioritize a positive candidate experience, enhancing your employer brand and attracting top talent in the competitive IT landscape.</li>
                            </ul>

                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <img src={interview} alt="Image" class="img-fluid p-2 h-100" style={{ borderRadius: "30px" }} />
                        </div>
                    </div>
                </div>
            </div>

            {/* hr selection */}
            <div className='interview-section'>
                <div class="container ">
                    <div class="row mt-5">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <img src={hrservice} alt="Image" class="img-fluid p-2 h-100" style={{ borderRadius: "30px" }} />
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <h2 className='p-2' >2. HR Services</h2>
                            <ul>
                                <li className='p-1'><strong>End-to-End Recruitment Solutions:</strong> From job postings to onboarding, we handle the recruitment process seamlessly, ensuring you secure the right talent quickly.</li>
                                <li className='p-1'><strong>Performance Management Systems:</strong> We implement performance management frameworks that encourage continuous feedback, development, and alignment with your strategic objectives.</li>
                                <li className='p-1'><strong>Compliance and Policy Guidance:</strong> Our team ensures that your HR policies adhere to legal standards, minimizing risks and fostering a safe workplace.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            {/* . Skill Development Programs */}
            <div className='interview-section'>
                <div class="container ">
                    <div class="row mt-5">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <h2 className='p-2'>3.Skill Development Programs</h2>
                            <ul className='mt-2'>
                                <li className='p-1'><strong>Tailored Training Sessions:</strong> We offer customized training that addresses the specific technologies and skills relevant to your manufacturing processes.</li>
                                <li className='p-1'><strong>Hands-On Workshops:</strong> Our interactive workshops provide practical experience, equipping your developers with the skills to tackle real-world challenges.</li>
                                <li className='p-1'><strong>Mentorship and Coaching:</strong> We facilitate mentorship opportunities, connecting junior developers with seasoned professionals for guidance and growth.</li>
                            </ul>


                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <img src={skill} alt="Image" class="img-fluid p-2 h-100" style={{ borderRadius: "30px" }} />
                        </div>
                    </div>
                </div>
            </div>


            <div className='pt-3 pb-3'>
                <h2 className='p-5 fw-bold text-center' style={{ fontFamily: "inter" }}>Get a Professional Services, Call us Now. (+91) 9524584817</h2>
            </div>






        </div>
    )
}
