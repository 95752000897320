
import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import logoimage1 from "../src/assets/logoimage1.png"; // This import is not used directly in the code
import Webdevelopmentpage from "./pages/Webdevelopmentpage";
import ScrollToTopButton from "./components/ScrollToTopButton.jsx";
import Resourcepage from "./pages/Resourcepage";
import Technologypage from "./pages/Technologypage";

// Lazy load components
const Home = lazy(() => import("./pages/Home"));
const Error = lazy(() => import("./pages/Error"));

// Custom Loader Component
const Loader = () => (
    <div className="loading-container">
        <span className="loader"></span>
    </div>
);

function App() {
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // 3 seconds

        // Clean up the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                {loading ? (
                    <Loader />
                ) : (
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/webdevelopment" element={<Webdevelopmentpage />} />
                        <Route path="/resourcemanagement" element={<Resourcepage />} />
                        <Route path="/technologytraining" element={<Technologypage />} />

                        <Route path="/*" element={<Error />} />

                    </Routes>
                )}
            </Suspense>
            <ScrollToTopButton />

        </BrowserRouter>
    );
}

export default App;
