import React from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { FaAngleUp } from 'react-icons/fa'

function ScrollToTopButton() {
    return (
        <ScrollToTop
            smooth
            component={
                <div>
                    <FaAngleUp style={{ color: 'blue', fontSize: '24px', }} />
                </div>
            }
            style={{
                
                position: 'fixed',
                bottom: '100px',
                right: '50px',
                zIndex: 1000,
                // background:'linear-gradient(0deg , #e30022,yellow,#006400)'

               
            }}
        />
    )
}

export default ScrollToTopButton
