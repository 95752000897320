import React from 'react';
import { Link } from 'react-router-dom';
import internalmentor from "../assets/internalmentor.jpg";
import rotationalmentor from "../assets/rotationalmentor.jpg";

export default function Technologypage() {
    return (
        <div>
            <div className='container'>
                <div className="h-25 w-50 pt-4 ">
                    <Link to="/" className='back-link '>
                        <span className='fs-4 p-2'>←</span>
                         Home
                    </Link>
                </div>
            </div>

            <div className="custom-text">
                <div className=" container ">
                    <h1 className='pt-2'>Technology Training Services</h1>
                    <p className='pt-2'>Empowering Manufacturing through Innovative IT Solutions and Customized Training Programs.</p>

                    <button className='contact-btn pt-2'
                        onClick={() => window.open('https://wa.me/9360118099?text=Hello, I would like to know more about your services!', '_blank')}
                    >Contact Us</button>
                </div>
            </div>


            {/* internal mentorship program */}
            <div className='interview-section'>
                <div class="container ">
                    <h1 style={{ fontFamily: "inter", fontWeight: "bold" }} class="text-center">Our Services</h1>
                    <div class="row mt-5">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <h2 className='p-2'>1.
                                Internal Mentorship Program</h2>

                            <ul className='mt-2'>
                                <li className='p-1'><strong>Introduction to Mentorship:</strong> Understanding mentorship's importance and the benefits for both mentees and mentors.</li>
                                <li className='p-1'><strong>Setting Goals for Mentorship:</strong> Establishing clear and achievable objectives using SMART goals to align aspirations with organizational objectives.</li>
                                <li className='p-1'><strong>Effective Communication Skills:</strong> Developing strong communication skills focusing on active listening, empathy, and constructive feedback.</li>
                                <li className='p-1'><strong>Building Trust and Rapport:</strong> Creating a safe space for discussions, maintaining confidentiality, and encouraging honest dialogue to strengthen connections.</li>

                            </ul>

                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">

                            <img src={internalmentor} alt="Image" class="img-fluid p-2 h-100 w-100" style={{ borderRadius: "30px" }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* rotaional mentorship program */}
            <div className='interview-section'>
                <div class="container ">
                    <div class="row mt-5">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <img src={rotationalmentor} alt="Image" class="img-fluid p-2 h-100" style={{ borderRadius: "30px" }} />
                        </div>

                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <h2 className='p-2' >2.Rotational Mentorship Program</h2>
                            <ul>
                                <li className='p-1'><strong>Program Structure:</strong> Define duration and rotation intervals; match mentees with different mentors based on goals.</li>
                                <li className='p-1'><strong>Diverse Exposure:</strong> Allow mentees to interact with mentors from various departments to gain broader insights.</li>
                                <li className='p-1'><strong>Goal Setting:</strong> Encourage clear personal development goals for each rotation, with mentor guidance.</li>
                                <li className='p-1'><strong>Regular Check-Ins:</strong> Schedule feedback sessions to discuss progress and challenges.</li>
                                <li className='p-1'><strong>Documentation and Reflection:</strong> Promote journaling of experiences and group reflection sessions for sharing insights.</li>

                            </ul>

                        </div>

                    </div>
                </div>
            </div>
            {/* contact  */}
            <div className='pt-3 pb-3'>
                <h2 className='p-5 fw-bold text-center' style={{ fontFamily: "inter" }}>Get a Professional Services, Call us Now. (+91) 9524584817</h2>
            </div>
        </div>
    )
}
